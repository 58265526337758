import React, { Component } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

class App extends Component {
  state = {
    direction: 'row',
    justify: 'center',
    alignItems: 'center',
    response: '',
    variant: ''
  };

  handleSubmit = async () => {
    const res = await axios.post(
      'https://pack-smart.herokuapp.com/api/products/',
      { code: this.state.code }
    );
    if (res.data.msg === 'validated') {
      this.setState({
        variant: 'success',
        response:
          'Thank you for purchasing Flonase. Your product has been validated. Please complete the registration to enjoy a 5$ discount for your next purchase'
      });
    } else if (res.data.msg === 'code is already validated') {
      this.setState({
        variant: 'warning',
        response: 'This product has already been validated!'
      });
    } else {
      this.setState({
        variant: 'error',
        response:
          `Invalid pin! Please type in again or/and submit a report of this issue. Thank you.`
      });
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  getHeight = () => {
    if(window) {
      if (window.innerWidth < 600) {
        return (window.innerWidth / 2)
      }
      else {
        return window.innerWidth / 4
      }  
    }
    else {
      return 400
    }
  }

  render() {
    const height = this.getHeight()
    const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);
    const { alignItems, direction, justify } = this.state;
    return (
      <div style={{ marginTop: 30 }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              spacing={24}
              alignItems={alignItems}
              direction={direction}
              justify={justify}
            >
              <Grid item xs={10} sm={8} md={8}>
                <div>
                  <p>1. Scratch the label</p>
                  <img
                    width="100%"
                    src="https://res.cloudinary.com/dma3agrae/image/upload/q_60/v1542578318/IMG_43587.jpg"
                    alt="scratch the label"
                  />
                </div>
                <p>2. Enter the pin code below. Validate your Flonase and enjoy $5 off your next purchase!</p>
                <Card style={{ padding: 20 }}>
                  <TextField
                    onChange={this.handleChange('code')}
                    style={{ width: '100%' }}
                    required
                    id="outlined-code-input"
                    label="Code"
                    type="number"
                    name="code"
                    margin="normal"
                    variant="outlined"
                  />
                  <Button
                    onClick={this.handleSubmit}
                    style={{ width: '100%', margin: 'auto' }}
                    variant="outlined"
                    color="primary"
                  >
                    Submit
                  </Button>
                  {this.state.response && (
                    <MySnackbarContentWrapper
                      style={{ margin: '10px auto' }}
                      variant={this.state.variant}
                      message={this.state.response}
                    />
                  )}
                  {this.state.variant === 'success' ? (<div style={{ marginTop: 5, textAlign: 'center'}}><a target="_blank" href="https://www.flonase.com/coupons/">Click here to get your Coupon</a></div>) : this.state.variant === 'error' ? (<div style={{marginTop: 5, textAlign:'center'}}><a target="_blank" href="http://ca.gsk.com/en-ca/contact-us/">Send Report</a></div>) : ""}
                  <div style={{ marginBottom: 10 }} />
                </Card>
              </Grid>
              <Grid item xs={10} sm={5} md={5}>

              <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.flonase.ca/"
                >
                  <img
                    width="100%"
                    src="https://res.cloudinary.com/dma3agrae/image/upload/v1541983560/flonase3.png"
                    alt="flonase website"
                  />
                </a>
              </Grid>
              <Grid item xs={10} sm={5} md={5}>
              <iframe title="how to open" width="100%" height={height} src="https://www.youtube.com/embed/dVY1OrqjjFA" frameborder="0" allowfullscreen></iframe>
              </Grid>
              <Grid item xs={10} sm={5} md={5}>
              <iframe title="how to use" width="100%" height={height}src="https://www.youtube.com/embed/85I7dtLIWWU" frameborder="0" allowfullscreen></iframe>
              </Grid>
              <Grid item xs={10} sm={5} md={5}>
              <iframe title="daily usage" width="100%" height={height}src="https://www.youtube.com/embed/fso3O1d9-W8" frameborder="0" allowfullscreen></iframe>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default App;
